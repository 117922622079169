// src/components/Menu.js
import React from 'react'
import { motion } from 'framer-motion'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { useTranslation } from '../../utils/localization.js'
import SettingsMenu from './settingsMenu.jsx'
import { useLogout } from '../../utils/platform_auth.jsx'
import Button from '../Button'
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/solid'
import { showPromptDialog } from '../PromptDialog'
import { navigate } from 'vike/client/router'
import { useAuth } from '../../utils/platform_auth'

const MenuMobile = ({ isOpen, setMenuOpen }) => {
  const t = useTranslation()
  const logout = useLogout()

  const auth = useAuth()
  const isLoggedIn = typeof auth === 'object'

  const variants = {
    open: {
      x: 0,
      transition: { type: 'tween', stiffness: 300, damping: 30 },
    },
    closed: {
      x: '-100%',
      transition: { type: 'tween', stiffness: 300, damping: 30 },
    },
  }

  const handleCloseMenu = () => {
    setMenuOpen(false)
  }

  const onLogout = () => {
    showPromptDialog({
      title: 'Logout',
      message: t`Are you sure you want to logout?`,
      onConfirm: () => {
        navigate('/')
        handleCloseMenu()
        setTimeout(() => logout(), 100)
      },
    })
  }

  return (
    <motion.div
      initial="closed"
      animate={isOpen ? 'open' : 'closed'}
      variants={variants}
      className="fixed inset-0 bg-paper flex flex-col items-start justify-start space-y-6 text-text text-xl px-6 pt-10 z-50"
    >
      <div className="w-full">
        <button
          onClick={handleCloseMenu}
          aria-label="PDF Translator Pro Menu"
          className="absolute top-4 left-4 fill-text"
        >
          <ChevronLeftIcon className="h-8 w-8 text-text" />
        </button>
        <div className="flex flex-col space-y-4 pt-10">
          <a href="/" onClick={handleCloseMenu}>{t`Home`}</a>
          <div className="w-full border-b border-bordered"></div>
          <a href="/translate" onClick={handleCloseMenu}>{t`Translate`}</a>
          <div className="w-full border-b border-bordered"></div>
          <a href="/blog" onClick={handleCloseMenu}>{t`Blog`}</a>

          {/* Show settings and log out only if logged in */}
          {isLoggedIn && (
            <>
              <div className="w-full border-b border-bordered"></div>
              <SettingsMenu handleCloseMenu={handleCloseMenu} />
              <div className="w-full border-b border-bordered"></div>
              <a
                onClick={onLogout}
                className="flex flex-row gap-1 items-center"
              >
                <ArrowLeftStartOnRectangleIcon className="h-5 mr-2" />
                {t`Logout`}
              </a>
            </>
          )}
        </div>
      </div>
    </motion.div>
  )
}

export default MenuMobile
