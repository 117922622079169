import React, { useRef, useEffect, useState } from 'react'
import { useTranslation } from '../../utils/localization.js'
import { SEARCH_QUERY_FLAGS } from '../../utils/routing.js'
import {
  useSearchParamMutation,
  useSearchParamValue,
} from '../../utils/searchParams.js'
import { AuthorizationButton } from '../AuthorizationButton/index.jsx'
import Button from '../Button/index.jsx'
import { Link } from '../Link/index.jsx'
import TextField from '../TextField/index.jsx'
import Typography from '../Typography/index.jsx'
import WaitingAuthBackdrop from '../WaitingAuthBackdrop/index.jsx'
import { useSigninUserWithCredentials } from '../../utils/platform_auth.jsx'
import { useAuth } from '../../utils/platform_auth.jsx'
import { navigate } from 'vike/client/router'

const errorToMessage = (error) => {
  if (error === 'invalid_email') {
    return 'Invalid email'
  }
  if (error == 'email_required') {
    return 'Email is required'
  }
}
export default function SignIn() {
  const emailRef = useRef()
  const { replace: replaceSearchParam } = useSearchParamMutation()
  const t = useTranslation()
  const { state, error, signinUser } = useSigninUserWithCredentials()
  const auth = useAuth()
  const redirectTo = useSearchParamValue(SEARCH_QUERY_FLAGS.RETURN_TO)

  //----------------------------------------------------------------
  //handle what happens after the user has signed in
  //----------------------------------------------------------------
  useEffect(() => {
    if (typeof auth !== 'object') return
    if (redirectTo) navigate(redirectTo, { overwriteLastHistoryEntry: true })
    else navigate('/', { overwriteLastHistoryEntry: true })
  }, [state, error, auth])
  //----------------------------------------------------------------

  const signinWithEmail = () => {
    let email = emailRef?.current?.value
    //trim and lowercase the email
    if (email) email = email.trim().toLowerCase()
    //sign in with the mail
    signinUser({ email })
  }

  return (
    <div className={'container mx-auto px-4'}>
      <WaitingAuthBackdrop
        email={emailRef?.current?.value}
        open={emailRef?.current?.value && state && state !== 'success'}
      />
      <div className="grid grid-cols-1 sm:grid-cols-1 max-w-[500px] mx-auto gap-5 px-8 pt-6 pb-8">
        <div className="flex flex-col justify-start">
          <div className="h-auto justify-center flex flex-col">
            <div className="flex items-center justify-center gap-4">
              <img
                className="h-16 w-16 self-center mb-5"
                src={`/static/images/logos/pdf-logo.webp`}
                alt="PDF Translator Pro Logo"
              />
              <Typography variant="h1" className="text-text text-center pb-6">
                {t`Sign in`}
              </Typography>
            </div>
            <Typography className="h-[25px] mt-5 text-error text-center">
              {errorToMessage(error)}
            </Typography>
          </div>
        </div>

        <div className="z-10">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            className="flex flex-col space-y-2"
          >
            <TextField
              label={t`Email`}
              type="email"
              placeholder={t`Enter your email`}
              inputRef={emailRef}
              className={`border-b-2 focus:outline-none focus:border-primary text-text text-sm ${'border-bordered'}`}
            />
            <div className="h-2" />
            <Button type="button" variant="action" onClick={signinWithEmail}>
              {t`Sign in`}
            </Button>
            <div className="flex items-center justify-center h-20">
              <Typography className="text-lg text-text">
                {t`Or continue with:`}
              </Typography>
            </div>
            <AuthorizationButton
              provider="google"
              label={t`Google`}
              onCompleted={signinUser}
            />
            <AuthorizationButton
              provider="apple"
              label={t`Apple`}
              onCompleted={signinUser}
            />
            <Typography className="justify-center text-center text-sm text-text_secondary pt-5">
              {t`By signing in you agree with our`}
              <Link
                to="/terms-of-use"
                className="text-link font-semibold hover:text-primary"
              >
                {' '}
                {t`Terms of Use`}
              </Link>{' '}
              {t`and`}
              <Link
                to="/privacy-policy"
                className="text-link font-semibold hover:text-primary"
              >
                {' '}
                {t`Privacy Policy`}
              </Link>
            </Typography>
          </form>
        </div>
      </div>
    </div>
  )
}
