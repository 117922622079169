import React from 'react'
import { useRecoilValueLoadable } from 'recoil'
import { navigate } from 'vike/client/router'
import AnimatedReviews from '../../components/AnimatedReviews'
import Button from '../../components/Button'
import DeviceSection from '../../components/DeviceSection/index.jsx'
import HowItWorks from '../../components/LandingPage/HowItWorks.jsx'
import MobileFeaturesSection from '../../components/MobileFeaturesSection'
import PromotionalRow from '../../components/PromotionalRow'
import Typography from '../../components/Typography'
import { useTranslation } from '../../utils/localization'
import { accountSelector, useAuth } from '../../utils/platform_auth'
import useThemeController from '../../utils/useThemeController'
import FeaturedArticle from '../../components/FeaturedArticle'
import AwardTopRated from '../../components/AwardTopRated/awardTopRated.jsx'
import AwardEaseOfUse from '../../components/AwardEaseOfUse/awardEaseOfUse.jsx'
import AwardRunsAnywhere from '../../components/AwardRunsAnywhere/awardRunsAnywhere.jsx'

const icons = ['excel_icon', 'word_icon', 'powerpoint_icon', 'pdf_icon']

const HomePage = () => {
  const t = useTranslation()
  const auth = useAuth()

  useThemeController()
  const { contents: account } = useRecoilValueLoadable(accountSelector)

  const getStarted = () => {
    navigate('/translate')
  }

  return (
    <div>
      {/* Header */}
      <div
        className="relative flex flex-col md:flex-row items-center justify-center w-full bg-white_to_black gap-10 md:gap-6 lg:gap-24 md:px-[70px] lg:px-[160px] py-[50px] sm:py-[70px] mx-0 sm:mx-auto"
      >
        {/* Left side */}
        <div
          className="flex flex-col w-full md:w-[70%] md:max-w-[600px] lg:w-[65%] md:h-[500px] items-center md:items-start justify-center md:justify-between gap-4 md:gap-6 md:pt-[60px] mx-auto md:mx-0"
        >
          <Typography
            variant="title"
            case="center"
            className="text-center md:text-left text-text"
          >
            {t`PDF Translator Pro`}
          </Typography>

          <Typography className="text-base sm:text-[18px] lg:text-[22px] text-text text-center md:text-left md:mb-[5px]">
            {t`Your personal document translator`}
          </Typography>

          <Button
            onClick={getStarted}
            variant="action"
            className="w-[200px] sm:w-[270px] max-w-[270px] h-[60px] sm:h-[60px] text-2xl shadow-lg my-8 sm:my-12 md:my-0"
          >
            {t`Translate Now`}
          </Button>

          <Typography className="text-text mb-6 md:mb-0">
            {t`Also available for`}
            {' '}
            <a
              href="https://apps.apple.com/us/app/pdf-translate-pro/id1609232374"
              target="_blank"
              className="text-link font-bold hover:underline"
            >
              {t`iOS`}
            </a>
            {' '}
            {t`and`}
            {' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.aldoo.pdftranslatorpro&hl=en&gl=US"
              target="_blank"
              className="text-link font-bold hover:underline"
            >
              {t`Android`}
            </a>
          </Typography>

          <div className="flex flex-col sm:flex-row gap-6 sm:gap-8 md:gap-5 lg:gap-6 items-end justify-start">
            <AwardTopRated />
            <AwardEaseOfUse />
            <AwardRunsAnywhere />
          </div>
        </div>

        {/* Right side */}
        <div
          className="flex flex-col items-center justify-center md:justify-between w-full md:w-[50%] md:max-w-[340px] lg:w-[35%] md:h-[500px]">
          <img
            src={`/static/images/logos/pdf-logo.webp`}
            className="w-[300px] sm:w-[340px] h-[auto] self-center"
            alt="PDF Translator Pro Logo"
          />

          {/* Render the icons in a row */}
          <div className="flex gap-6 sm:gap-10 mt-5 mb-10 md:mb-2">
            {icons.map((icon, index) => (
              <img
                key={index}
                width={50}
                className="w-[40px]"
                src={`/static/images/icons/${icon}.webp`}
                alt={icon}
              />
            ))}
          </div>

          <HowItWorks />
        </div>
      </div>
      <PromotionalRow />
      <AnimatedReviews />
      <DeviceSection />
      <MobileFeaturesSection />
      <FeaturedArticle />
    </div>
  )
}

export default HomePage
