import { UserIcon } from '@heroicons/react/24/solid'
import { useRecoilValueLoadable } from 'recoil'
import { useTranslation } from '../../utils/localization.js'
import Navigation from '../../utils/navigation.js'
import {
  accountSelector,
  subscriptionSelector,
  useAuth,
} from '../../utils/platform_auth.jsx'
import { SEARCH_QUERY_FLAGS } from '../../utils/routing.js'
import { useSearchParamMutation } from '../../utils/searchParams.js'
import AddCreditsButton from '../AddCreditsButton/index.jsx'
import PricingButton from '../PricingButton/index.jsx'
import { Spinner } from '../Spinner/index.jsx'
import Typography from '../Typography/index.jsx'
import DiscountBar from './discountBar.jsx'
import AwardTopRated from '../AwardTopRated/awardTopRated.jsx'

export default function NavbarDesktop() {
  const t = useTranslation()
  const auth = useAuth()

  const isLoggedIn = typeof auth === 'object'

  const { add: addSearchParam } = useSearchParamMutation()

  const { contents: account, state: accountState } = useRecoilValueLoadable(
    accountSelector,
  )
  const {
    contents: subscription,
    state: subscriptionState,
  } = useRecoilValueLoadable(subscriptionSelector)

  const loaded = accountState !== 'loading' && subscriptionState !== 'loading'

  return (
    <div>
      <div
        className="h-[128px] w-full flex flex-col items-center justify-center border-b border-bordered"
        style={{ zIndex: 0 }}
      ></div>
      <div
        className="fixed top-0 left-0 w-full flex flex-col items-center justify-center border-b-[1px] border-bordered"
        style={{ zIndex: 200 }}
      >
        <DiscountBar />

        {/* Main NavBar */}
        <div className="w-full h-16 select-none bg-navbar flex justify-between lg:items-stretch">
          <div className="flex flex-no-shrink items-stretch h-16">
            <a
              className="flex-no-grow flex-no-shrink relative gap-3 py-2 px-4 leading-normal text-text no-underline flex items-center hover:bg-canvas"
              href="/"
              aria-label="Go to PDF Translator Pro Home"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                Navigation.goTo('/')
              }}
            >
              <img
                className="h-10 w-10 self-center "
                src={`/static/images/logos/pdf-logo.webp`}
                alt="PDF Translator Pro Logo"
              />

              <Typography
                variant="subtitle"
                className="flex text-center text-text font-semibold items-center mr-4"
              >
                PDF Translator Pro
              </Typography>
            </a>

            <a
              className="flex h-[64px] items-center flex-no-grow flex-no-shrink relative py-2 px-4 leading-normal font-normal text-text hover:text-primary no-underline"
              href={
                isLoggedIn
                  ? '/translate'
                  : `/?${SEARCH_QUERY_FLAGS.SIGNIN}&${
                      SEARCH_QUERY_FLAGS.RETURN_TO
                    }=${encodeURIComponent('/translate')}`
              }
            >
              <div className="flex h-[64px] items-center justify-center hover:bg-canvas text-text py-2 px-4 rounded transition duration-200 ease-in-out">
                {t`Translate`}
              </div>
            </a>

            <a
              className="flex h-[64px] items-center flex-no-grow flex-no-shrink relative py-2 px-4 leading-normal font-normal text-text hover:text-primary no-underline"
              href="/blog"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                Navigation.goTo('/blog')
              }}
            >
              <div className="flex h-[64px] items-center justify-center hover:bg-canvas text-text py-2 px-4 rounded transition duration-200 ease-in-out">
                {t`Blog`}
              </div>
            </a>
            <PricingButton />
          </div>

          <div className="flex flex-no-shrink items-center h-16 justify-center">
            {!loaded && <Spinner className="mr-4" />}
            {loaded && isLoggedIn && (
              <div
                className={`relative flex flex-no-grow flex-no-shrink leading-normal font-semibold text-text no-underline flex items-center hover:bg-canvas py-2 px-4`}
              >
                <AddCreditsButton />
              </div>
            )}

            {loaded && isLoggedIn && (
              <a
                className="navitem flex-no-grow flex-no-shrink relative py-2 px-4 leading-normal text-text no-underline flex items-center hover:bg-canvas"
                href="/user?section=profile"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  Navigation.goTo('/user?section=profile')
                }}
              >
                <UserIcon className="p-2 h-12 w-12 border border-bordered rounded-full" />
              </a>
            )}

            {loaded && !isLoggedIn && (
              <div className="h-16 flex flex-row items-center justify-center flex-no-shrink gap-8 pr-4">
                <div className="hidden lg:flex">
                  <AwardTopRated />
                </div>

                <Typography className="hidden md:block text-text text-lg font-bold">
                  {t`Get Your Account Now!`}
                </Typography>

                <a
                  className="no-underline"
                  href={addSearchParam(SEARCH_QUERY_FLAGS.SIGNIN)}
                >
                  <div className="min-w-[130px] flex text-center justify-center leading-normal text-link hover:text-white font-bold bg-none hover:bg-primary border border-bordered hover:border-link py-2 px-4 rounded-full transition duration-200 ease-in-out">
                    {t`Sign in`}
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
