import React, { useState } from 'react'
import Button from '../Button'
import Divider from '../../components/Divider'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import ContactPanel from '../ContactPanel'

export default function FooterNormal() {
  const t = useTranslation()
  const [contactOpen, setContactOpen] = useState(false)

  return (
    <div className="flex w-full select-none bg-footer border-t-[1px] border-bordered px-4">
      {/* Footer container */}
      <div className="w-full sm:w-[580px] md:w-[920px] lg:w-[1100px] mx-auto">
        {/* Upper grid menu */}
        <div className="flex flex-wrap justify-between w-full gap-y-8 py-10 sm:pl-[100px] md:px-0 mx-auto">
          <ContactPanel
            isOpen={contactOpen}
            onCloseRequested={() => setContactOpen(false)}
          />
          {/* PDF Translator Pro */}
          <div className="w-full sm:w-1/2 md:w-1/4 md:max-w-[200px]">
            <Typography className="footer-title text-text mb-2">
              PDF Translator Pro
            </Typography>
            <ul>
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="/"
                  alt="Home"
                  aria-label="Go to PDF Translator Pro Home"
                >{t`Home`}</a>
              </li>
              {/* <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="#"
                >{t`Tools`}</a>
              </li> */}
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="/faq"
                  alt="FAQ"
                  aria-label="Frequently Asked Questions"
                >{t`FAQ`}</a>
              </li>
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="/blog"
                  alt="Blog"
                  aria-label="Blog"
                >{t`Blog`}</a>
              </li>
              {/*<li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="#"
                >{t`Help`}</a>
              </li>*/}
            </ul>
          </div>
          {/* Contact Us */}
          <div className="w-full sm:w-1/2 md:w-1/4 md:max-w-[200px]">
            <Typography className="footer-title text-text mb-2">
              {t`Contact Us`}
            </Typography>
            <ul>
              <li>
                <div
                  className="cursor-pointer footer-item text-text_secondary hover:text-text"
                  onClick={() => setContactOpen(true)}
                >{t`Contacts`}</div>
              </li>
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="https://www.youtube.com/@pdftranslatepro"
                  target="_blank"
                  aria-label="Find us on Youtube"
                >{t`Find us on Youtube`}</a>
              </li>
            </ul>
          </div>
          {/* Solutions */}
          {/*<div className="w-full sm:w-1/2 md:w-1/4 md:max-w-[200px]">
            <Typography className="footer-title text-text mb-2">
              {t`Solutions`}
            </Typography>
            <ul>
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="#"
                >{t`Business`}</a>
              </li>
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="#"
                >{t`Education`}</a>
              </li>
            </ul>
          </div> */}
          <div className="w-full sm:w-1/2 md:w-1/4 md:max-w-[200px]">
            <Typography className="footer-title text-text mb-2">
              {t`Terms and Privacy`}
            </Typography>
            <ul>
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="/terms-of-use"
                >{t`Terms of Use`}</a>
              </li>
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="/privacy-policy"
                >{t`Privacy Policy`}</a>
              </li>
            </ul>
          </div>
          {/* Company */}
          {/* <div className="w-full sm:w-1/2 md:w-1/4 md:max-w-[200px]">
            <Typography className="footer-title text-text mb-2">
              {t`Company`}
            </Typography>
            <ul>
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="#"
                >{t`Our Story`}</a>
              </li>
              <li>
                <a
                  className="footer-item text-text_secondary hover:text-text"
                  href="#"
                >{t`Blog`}</a>
              </li>
            </ul>
          </div> */}
        </div>

        {/* Buttons */}
        <div>
          <div className="flex flex-wrap justify-between justify-self-auto items-center w-full mb-6">
            {/* Language Selector - hide until ready */}
            {/*
          <Button
            variant="outlined"
            className="border-bordered hover:border-primary text-text_secondary hover:text-link"
          >
            {t`Language Selector`}
          </Button>*/}

            <div className="flex flex-row mx-auto">
              <div className="px-5 py-5 w-[250px] md:flex-1">
                <a
                  href="https://apps.apple.com/us/app/pdf-translator-pro/id1609232374"
                  target="_blank"
                  aria-label="Download on the App Store"
                >
                  <img src="/static/images/app_store.webp" alt="Apple Store" />
                </a>
              </div>
              {/* Temporary margin until LanguageSelector is back*/}
              <div className="px-5 py-5 w-[250px] md:flex-1">
                <a
                  href="https://play.google.com/store/apps/details?id=com.aldoo.pdftranslatorpro&hl=en&gl=US"
                  target="_blank"
                  aria-label="Get it on Google Play"
                >
                  <img
                    src="/static/images/google_play.webp"
                    alt="Google Play"
                  />
                </a>
              </div>
            </div>
          </div>
          <Divider />
          <div className="flex flex-wrap justify-center items-center w-full text-text_secondary py-4">
            &copy; {new Date().getFullYear()} PDF Translator Pro
          </div>
        </div>
      </div>
    </div>
  )
}
