import React, { useState } from 'react';
import Button from '../Button'
import Divider from '../../components/Divider'
import Typography from '../Typography'
import { useTranslation } from '../../utils/localization'
import ContactPanel from '../ContactPanel'

const FooterAccordion = () => {
  const t = useTranslation()
  const [contactOpen, setContactOpen] = useState(false)

  const [openSection, setOpenSection] = useState(null);
  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="flex flex-col w-full bg-footer text-text py-6">
      <div className="container mx-auto px-4 mb-8">
        <ContactPanel
          isOpen={contactOpen}
          onCloseRequested={() => setContactOpen(false)}
        />
        {/* Accordion */}
        <div className="accordion flex flex-col gap-5">
          {/* <AccordionSection
            title="PDF Translator Pro"
            isOpen={openSection === 'section1'}
            toggle={() => toggleSection('section1')}
          >
            <a href="/" className="block text-text_secondary hover:text-text py-1">{t`Home`}</a>
            <a href="#" className="block text-text_secondary hover:text-text py-1">{t`Tools`}</a>
            <a href="/faq" className="block text-text_secondary hover:text-text py-1">{t`FAQ`}</a>
          </AccordionSection> */}
          <a href="/faq" className="w-full flex justify-between text-base font-bold text-text text-left py-2 border-b border-bordered">{t`FAQ`}</a>
          <a href="/blog" className="w-full flex justify-between text-base font-bold text-text text-left py-2 border-b border-bordered">{t`Blog`}</a>
          <AccordionSection
            title="Contact Us"
            isOpen={openSection === 'section2'}
            toggle={() => toggleSection('section2')}
          >
            <a
              onClick={() => setContactOpen(true)}
              className="block text-text_secondary hover:text-text py-1"
            >
              {t`Contacts`}
            </a>
            <a
              href="https://www.youtube.com/@pdftranslatepro"
              target="_blank"
              className="block text-text_secondary hover:text-text py-1"
            >
              {t`Find us on YouTube`}
            </a>
          </AccordionSection>
          <AccordionSection
            title="Terms and Privacy"
            isOpen={openSection === 'section3'}
            toggle={() => toggleSection('section3')}
          >
            <a
              href="/terms-of-use"
              className="block text-text_secondary hover:text-text py-1"
            >
              {t`Terms and Conditions`}
            </a>
            <a
              href="/privacy-policy"
              className="block text-text_secondary hover:text-text py-1"
            >
              {t`Privacy Policy`}
            </a>
          </AccordionSection>
          {/* <AccordionSection
            title="Company"
            isOpen={openSection === 'section4'}
            toggle={() => toggleSection('section4')}
          >
            <a href="#" className="block text-text_secondary hover:text-text py-1">{t`Our Story`}</a>
            <a href="#" className="block text-text_secondary hover:text-text py-1">{t`Blog`}</a>
          </AccordionSection> */}
        </div>
      </div>
      {/* Store buttons */}
      <div className="flex w-full justify-center mb-4">
        <Button variant="store">
          <a
            href="https://play.google.com/store/apps/details?id=com.aldoo.pdftranslatorpro&hl=en&gl=US"
            target="_blank"
            aria-label="Get it on Google Play"
          >
            <img src="/static/images/google_play.webp" alt="Google Play" />
          </a>
        </Button>
        <Button variant="store">
          <a
            href="https://apps.apple.com/us/app/pdf-translator-pro/id1609232374"
            aria-label="Download on the App Store"
            target="_blank"
          >
            <img src="/static/images/app_store.webp" alt="Apple Store" />
          </a>
        </Button>
      </div>
      <Divider />
        <div className="flex flex-wrap justify-center items-center w-full text-text_secondary py-4">
          &copy; {new Date().getFullYear()} PDF Translator Pro
        </div>
    </div>
  );
};

const AccordionSection = ({ title, isOpen, toggle, children }) => {
  const t = useTranslation()

  return (
    <div>
      <button
        onClick={toggle}
        className="w-full flex justify-between text-base font-bold text-text text-left py-2 border-b border-bordered"
      >
        {t(title)}
        <span className="text-text">{isOpen ? '-' : '+'}</span>
      </button>
      {isOpen && <div className="pl-4 py-4">{children}</div>}
    </div>
  );
};

export default FooterAccordion;
